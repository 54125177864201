import React, { RefObject, useRef } from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";

interface LayoutProps {
  children: JSX.Element;
}

export type EmailInput = RefObject<HTMLInputElement | null>;

const Layout = ({ children }: LayoutProps): JSX.Element => {
  const emailInput = useRef<EmailInput>(null);

  const handleInputFocus = () => {
    if (emailInput && emailInput.current !== null) {
      emailInput.current?.focus();
    }
  };

  return (
    <>
      <Navbar onBookClick={handleInputFocus} />
      <main>{React.cloneElement(children, { emailInputRef: emailInput })}</main>
      <Footer onBookClick={handleInputFocus} />
    </>
  );
};

export default Layout;
