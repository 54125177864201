import { useStyles } from "../../Navbar.styles";
import * as React from "react";
import { IconLogo } from "../../../../icons/IconLogo";

const LogotypeSvg = () => {
  const classes = useStyles();

  return (
    <div className={classes.logotype}>
      <IconLogo />
    </div>
  );
};

export default LogotypeSvg;
