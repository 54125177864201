import React from "react";
import { useStyles } from "./Footer.styles";
import { Grid, Link, Typography } from "@material-ui/core";
import { IconLogo } from "../../icons/IconLogo";
import Button from "@material-ui/core/Button";

interface FooterProps {
  onBookClick: () => void;
}

const Footer = ({ onBookClick }: FooterProps): JSX.Element => {
  const classes = useStyles();

  return (
    <footer className={classes.footer} id="contact">
      <Grid container>
        <Grid item xs={12} md={6}>
          <IconLogo />
          <Typography variant="body2" className={classes.description}>
            EnergyPlatform.TECH is an asset performance management platform for
            distributed energy resources that allow asset managers, performance
            engineers and O&M teams to identify losses, pinpoint cause of budget
            and model deviation.
          </Typography>
          <Button
            color="secondary"
            variant="contained"
            size="small"
            className={classes.button}
            onClick={onBookClick}
          >
            Book a demo
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid
            container
            alignContent="flex-start"
            className={classes.linksContainer}
          >
            <Grid item xs={4}>
              <Typography variant="body1" className={classes.header}>
                Company:
              </Typography>
              <Link
                variant="body1"
                className={classes.item}
                href="https://codibly.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Codibly
              </Link>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" className={classes.header}>
                Follow us:
              </Typography>
              <Link
                variant="body1"
                className={classes.item}
                href="https://www.linkedin.com/company/codibly/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Linkedin
              </Link>
              <br />
              <Link
                variant="body1"
                className={classes.item}
                href="https://clutch.co/profile/codibly"
                target="_blank"
                rel="noopener noreferrer"
              >
                Clutch
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
