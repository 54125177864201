import { useStyles } from "../Navbar.styles";
import * as React from "react";
import LogotypeSvg from "./LogotypeSvg/LogotypeSvg";

const LogoBox = () => {
  const classes = useStyles();

  return (
    <div className={classes.logotype}>
      <LogotypeSvg />
    </div>
  );
};

export default LogoBox;
