import React from "react";
import { useStyles } from "./HeadingDash.styles";

type HeadingDashProps = {
  justify: "center" | "flex-start";
};

const HeadingDash = ({ justify }: HeadingDashProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div style={{ justifyContent: justify }} className={classes.wrapper}>
      <hr />
    </div>
  );
};

export default HeadingDash;
