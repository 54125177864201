import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  wrapper: {
    display: "flex",

    "& hr": {
      margin: "8px 2px",
      width: "50px",
      height: "3px",
      backgroundColor: "#FF9900",
      border: "none",
      borderRadius: "10px",
    },
  },
});
