import { makeStyles } from "@material-ui/core";

const text_color = "#F6F3F0";

export const useStyles = makeStyles({
  footer: {
    paddingLeft: "10%",
    paddingTop: "90px",
    paddingBottom: "90px",
  },
  description: {
    color: text_color,
    padding: 0,
    margin: "32px 50px 32px 0",
  },
  button: {
    color: "white",
    padding: "0px 10px",
    borderRadius: "30px",
    fontWeight: "800",
    height: "40px",
  },
  linksContainer: {
    marginTop: "84px",
  },
  header: {
    color: text_color,
  },
  item: {
    color: text_color,
    cursor: "pointer",
  },
});
