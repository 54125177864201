import { makeStyles } from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: "0px",
      margin: "12px 16px 12px 16px",
      cursor: "pointer",
      borderRadius: 0,
      fontWeight: 600,
      borderBottom: "2px solid transparent",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        borderColor: theme.palette.secondary.main,
      },
      [theme.breakpoints.down("sm")]: {
        padding: "20px",
        margin: 0,
      },
    },
  })
);
