import { makeStyles } from "@material-ui/core";
import {
  NAVBAR_HEIGHT,
  NAVBAR_HEIGHT_SM,
} from "../../const/navbarHeight/navbarHeight";

export const useStyles = makeStyles((theme) => ({
  bar: {
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    background: theme.palette.background.default,
    border: "none",
    boxShadow: "none",
    position: "fixed",
    height: `${NAVBAR_HEIGHT}px`,

    [theme.breakpoints.down("xs")]: {
      height: `${NAVBAR_HEIGHT_SM}px`,
      position: "sticky",
    },
  },
  navMenu: {
    display: "flex",
    padding: theme.spacing(1),
    position: "absolute",
    top: "8px",
    right: "8px",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      top: `${NAVBAR_HEIGHT_SM}px`,
      right: 0,
      margin: 0,
      padding: 0,
      width: "100vw",
      height: `calc(100vh - ${NAVBAR_HEIGHT_SM}px)`,
      display: "flex",
      position: "fixed",
      background: theme.palette.background.default,
      borderTop: `1px solid ${theme.palette.warning.main}`,
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },

    "& button": {
      color: "#fff",
      padding: "0px 10px",
      margin: "10px",
      border: "none",
      boxSizing: "border-box",
      borderRadius: "30px",
      fontWeight: "800",
      height: "40px",
      "& img": {
        padding: "2rem",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "20px",
      },
    },
  },
  mobileOpen: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
    // TODO transition transform 0, animate
  },
  mobileClosed: {
    [theme.breakpoints.down("xs")]: {
      display: "none !important",
    },
    // TODO pos absolute,  transition transform => move top
  },
  mobileServices: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      display: "flex",
      position: "fixed",
      background: theme.palette.background.default,
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  services: {
    marginRight: "10px",
    fontWeight: 600,
    cursor: "pointer !important",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      padding: "20px",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
    },
  },
  servicesListItem: {
    fontWeight: 600,
    fontSize: "16px",
    width: "100vw",
    color: theme.palette.warning.main,
    cursor: "pointer !important",
  },
  menuIconBtn: {
    display: "none",
    margin: "0 24px 0 0",
    padding: 0,
    color: "#fff !important",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
  logotype: {
    padding: "12px 0px 0px 32px",
    "& img": {
      width: "80%",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "10px 0px 0px 12px",
      position: "static",
      "& img": {
        width: "60%",
      },
    },
  },
}));
