import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import { useStyles } from "./Navbar.styles";
import {
  Box,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import LogoBox from "./LogoBox/LogoBox";
import CustomMenuItem from "./CustomMenuItem/CustomMenuItem";
import { Link } from "gatsby";

const menuItemStyles = { textDecoration: "none", color: "black" };

interface NavbarProps {
  onBookClick: () => void;
}

const Navbar = ({ onBookClick }: NavbarProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallViewPort = useMediaQuery(theme.breakpoints.down("xs"));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mouseOverButton, setMouseOverButton] = useState<boolean>(false);
  const [mouseOverMenu, setMouseOverMenu] = useState<boolean>(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [mobileServicesOpen, setMobileServicesOpen] = useState<boolean>(false);

  const handleClose = () => {
    setAnchorEl(null);
    setMouseOverButton(false);
    setMouseOverMenu(false);
  };

  const handleToggleMobileMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (!isSmallViewPort) return;
    setMobileMenuOpen((mobileMenuOpen) => !mobileMenuOpen);
    setMobileServicesOpen(false);
  };

  const handleToggleServicesMobile = () => {
    setMobileServicesOpen((mobileServicesOpen) => !mobileServicesOpen);
  };

  const openServicesDesktop = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMouseOverButton(true);
  };

  const handleServicesClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isSmallViewPort) {
      handleToggleServicesMobile();
    } else {
      openServicesDesktop(event);
    }
  };

  const leaveButton = () => {
    // Set a timeout so that the menu doesn't close before the user has time to
    // move their mouse over it
    setTimeout(() => {
      setMouseOverButton(false);
    }, 3000);
  };

  const enterMenu = () => {
    setMouseOverMenu(true);
  };

  const leaveMenu = () => {
    setTimeout(() => {
      setMouseOverMenu(false);
    }, 500);
  };

  const open = mouseOverButton || mouseOverMenu;

  return (
    <>
      <AppBar className={classes.bar} position="absolute">
        <Link to="/" style={menuItemStyles}>
          <LogoBox />
        </Link>
        <Box
          className={`${classes.navMenu} ${
            mobileMenuOpen ? classes.mobileOpen : classes.mobileClosed
          }`}
        >
          {/*<div>*/}
          <Typography
            variant="button"
            // component="div"
            onMouseEnter={openServicesDesktop}
            onMouseLeave={leaveButton}
            onClick={handleServicesClick}
            className={classes.services}
          >
            Services
          </Typography>
          {!isSmallViewPort && (
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              MenuListProps={{
                onMouseEnter: enterMenu,
                onMouseLeave: leaveMenu,
              }}
              getContentAnchorEl={null}
            >
              <Link to="/services/SolarEP" style={menuItemStyles}>
                <MenuItem onClick={handleClose}>SolarEP</MenuItem>
              </Link>
              <Link to="/services/EVoEP" style={menuItemStyles}>
                <MenuItem onClick={handleClose}>EVoEP</MenuItem>
              </Link>
              <Link to="/services/OptoEP" style={menuItemStyles}>
                <MenuItem onClick={handleClose}>OptoEP</MenuItem>
              </Link>
            </Menu>
          )}
          {/* in nex iteration add <Collapse> from m-ui */}
          {isSmallViewPort && (
            <List
              className={`${classes.mobileServices} ${
                mobileServicesOpen ? classes.mobileOpen : classes.mobileClosed
              }`}
            >
              <Link to="/services/SolarEP" style={menuItemStyles}>
                <ListItem
                  onClick={handleClose}
                  className={classes.servicesListItem}
                >
                  SolarEP
                </ListItem>
              </Link>
              <Link to="/services/EVoEP" style={menuItemStyles}>
                <ListItem
                  onClick={handleClose}
                  className={classes.servicesListItem}
                >
                  EVoEP
                </ListItem>
              </Link>
              <Link to="/services/OptoEP" style={menuItemStyles}>
                <ListItem
                  onClick={handleClose}
                  className={classes.servicesListItem}
                >
                  OptoEP
                </ListItem>
              </Link>
            </List>
          )}
          {/*</div>*/}
          {/*<CustomMenuItem to="/marketplace">Marketplace</CustomMenuItem>*/}
          <CustomMenuItem to="/#contact">Contact</CustomMenuItem>
          <Button
            color="secondary"
            variant="contained"
            size="small"
            onClick={onBookClick}
          >
            Book a demo
          </Button>
        </Box>
        <IconButton
          edge="start"
          className={classes.menuIconBtn}
          aria-label="menu"
          onClick={handleToggleMobileMenu}
        >
          <MenuIcon />
        </IconButton>
      </AppBar>
    </>
  );
};

export default Navbar;
