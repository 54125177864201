import React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./CustomMenuItem.styles";
import { Link } from "gatsby";

interface Props {
  children: string;
  to: string;
}

const CustomMenuItem = ({ children, to }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Typography variant="button" className={classes.container} component="div">
      <Link to={to} style={{ textDecoration: "none", color: "white" }}>
        {children}
      </Link>
    </Typography>
  );
};

export default CustomMenuItem;
