import { createTheme } from "@material-ui/core/styles";
import "@fontsource/open-sans";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/500.css";

export const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          backgroundColor: "#190A30",
          scrollBehavior: "smooth",
          fontDisplay: "block",
          fontFamily: "Open Sans, Roboto, sans-serif, serif",
        },
        body: {
          width: "100vw",
          padding: 0,
          margin: 0,
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "calc(.2rem + 50%)",
          fontFamily: "Open Sans, Roboto, sans-serif, serif",
          lineHeight: "1.8rem",
          backgroundColor: "#190A30",
        },
        main: {
          width: "100vw",
          padding: 0,
          margin: 0,
          "& section": {
            overflow: "hidden",
          },
          "& ul": {
            marginTop: "30px",
            listStyle: "none",
          },
          "& li": {
            fontSize: "14px",
            fontWeight: 300,
          },
          "& li::before": {
            color: "#FF9900",
            content: '"\\2022"',
            fontSize: "30px",
            fontWeight: "bold",
            display: "inline-block",
            width: ".8em",
            marginLeft: "-1.2em",
            verticalAlign: "middle",
            lineHeight: "34px",
          },
        },
      },
    },
  },

  typography: {
    h1: {
      marginTop: "20vh",
      margin: "0 10%",
      fontWeight: 700,
      fontSize: "38px",
      lineHeight: "2.8rem",
      fontFamily: "Open Sans, Roboto, sans-serif, serif",
      "@media (max-width:600px)": {
        marginTop: "2vh",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "24px",
        lineHeight: "1.8rem",
        letterSpacing: "-0.05em",
      },
    },
    h2: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "28px",
      lineHeight: "40px",
      color: "#333333",
      fontFamily: "Open Sans, Roboto, sans-serif, serif",
      "@media (max-width:600px)": {
        fontSize: "16px",
        lineHeight: "1.8rem",
      },
    },
    h3: {
      margin: "16px 0",
      fontWeight: 600,
      marginLeft: "10%",
      fontSize: "20px",
      style: "normal",
      lineHeight: "22px",
      fontFamily: "Open Sans, Roboto, sans-serif, serif",
      "@media (max-width:600px)": {
        fontSize: "18px",
        lineHeight: "1.8rem",
        "& br": {
          display: "none",
        },
      },
    },
    h4: {
      padding: "2% 0",
      fontWeight: 600,
      fontSize: "18px",
      color: "#121725",
      fontFamily: "Open Sans, Roboto, sans-serif, serif",
    },
    button: {
      color: "#fff",
      borderRadius: "80px",
      textTransform: "initial",
      fontStyle: "normal",
      fontWeight: 700,
      fontFamily: "Open Sans, Roboto, sans-serif, serif",
      padding: "8px",
      whiteSpace: "nowrap",
    },
    body2: {
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "14px",
    },
  },

  palette: {
    background: {
      default: "#190A30",
      paper: "#fff",
      // additional gray #F6F3F0
    },
    primary: {
      main: "#190A30",
    },
    secondary: {
      light: "#ffc779",
      main: "#FFAB2D",
      dark: "#cf7901",
    },
    warning: {
      main: "#FF9900",
    },
    info: {
      main: "#83d7d4",
    },
    grey: {
      50: "#F6F3F0",
    },
  },
});
